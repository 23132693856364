import { DELETE_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, STUCK_CLAIMS_THRESHOLDS_MANA } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "vue";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Thresholds Management", "search-by": "KeyAccountDescription,KeyAccountCode", "ph-search-by": "Search by Key Account Code, Key Account", "scroll": { x: 1600 } }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_vm.can(_vm.permissions.add) ? _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v(" Add Key Account ")]) : _vm._e()];
  } }, _vm.can(_vm.permissions.edit) ? { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "disabled": record.isInactive ? true : false, "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1), _vm.can(_vm.permissions.delete) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()];
  } } : null], null, true) }, [_c("text-field", { key: "Region", attrs: { "data-index": "region", "title": "Region", "width": 150, "sorter": true } }), _c("text-field", { key: "keyAccountCode", attrs: { "data-index": "keyAccountCode", "title": "Key Account", "parse": _vm.parseKeyAccount, "sorter": true } }), _c("text-field", { key: "Variance", attrs: { "data-index": "variance", "title": "$ Variance Threshold", "parse": _vm.formatCurrency } }), _c("text-field", { key: "Impact", attrs: { "data-index": "impact", "title": "$ Impact Threshold", "parse": _vm.formatCurrency } })], 1);
};
var staticRenderFns$1 = [];
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 4
});
const __vue2_script$1 = {
  name: "ListThreshold",
  inject: ["can", "crud"],
  data() {
    return {
      permissions: {
        delete: DELETE_PERMISSION,
        add: ADD_PERMISSION,
        edit: EDIT_PERMISSION
      }
    };
  },
  created() {
    this.crud.setPagination({ page: 1, pageSize: 40 });
  },
  methods: {
    formatCurrency(value) {
      return value !== null ? usdFormatter.format(value) : "";
    },
    parseKeyAccount(keyAccountCode, record) {
      return `${keyAccountCode} - ${record.keyAccountDescription}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListTable = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-thresholds" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "stuck-claims.thresholds", "api-url": _vm.apiUrl, "create-route": "/stuck-claims/thresholds-management/create", "edit-route": "/stuck-claims/thresholds-management/:id", "page": _vm.page } }, [_c("list-table")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListTable
  },
  data() {
    return {
      apiUrl,
      page: STUCK_CLAIMS_THRESHOLDS_MANA,
      itemsMenu: [
        {
          title: "Master Files",
          path: ""
        },
        {
          title: "Thresholds Management",
          path: "/stuck-claims/thresholds-management"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
